<template>
	<div class="pat3">
		<div class="main">
			<b>Participate process</b>
			<h1>参与流程</h1>

			<el-image :src="participatePath" fit="cover"></el-image>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pat3',
		props: {
			participatePath: String
		},
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>
<style lang="scss" scoped>
	.pat3 {
		padding: 100px 0;

		.main {
			width: 1200px;
			margin: auto;
			display: flex;
			flex-direction: column;

			b {
				color: #28B0A6;
				font-size: 16px;
			}

			h1 {
				font-size: 70px;
				font-weight: 600;
				line-height: 1.5;
			}

			.el-image {
				width: 100%;
				// height: 650px;
				margin-top: 90px;
				// box-shadow: 0 0 10px rgba(102, 102, 102, .1);
			}
		}
	}
</style>