<template>
	<div class="home">
		<part1 :bannerPath="partData.bannerPath" />
		<part2 :projectData="ProjectData" />
		<part3 :participatePath="partData.participatePath" />
		<!-- <part4 :newsData="newsData" /> -->
		<!-- <part5 /> -->
		<!-- <part6 /> -->
	</div>
</template>

<script>
	import part1 from '../components/home/homePart1'
	import part2 from '../components/home/homePart2'
	import part3 from '../components/home/homePart3'
	// import part4 from '../components/home/homePart4'
	// import part5 from '../components/home/homePart5'
	// import part6 from '../components/home/homePart6'
	import {
		getConfig, // 首页获取logo banner 流程图  
		getProject, // 首页项目介绍
		news // 项目动态
	} from '@/request/api.js';
	export default {
		name: 'home',
		components: {
			part1,
			part2,
			part3,
			// part4,
			// part5,
			// part6
		},
		data() {
			return {
				partData: '',
				ProjectData: {},
				newsData: []
			}
		},
		created() {
			this.getConfigFun();
			this.getProjectFun();
			this.getNews();
		},
		mounted() {},
		methods: {
			getNews() {
				let data = {
					current: 1,
					size: 4
				}
				news(data).then(res => {
					if (res.code == 200) {
						this.newsData = res.data.records;
					}
				})
			},

			// 首页项目介绍
			getProjectFun() {
				getProject().then(res => {
					if (res.code == 200) {
						let data = res.data;
						this.ProjectData = data;
					}
				})
			},

			// 首页获取logo banner 流程图  
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.partData = res.data;
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
</style>