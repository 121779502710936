<template>
	<div class="pat1">
		<el-image class="background" :src="bannerPath" fit='cover'></el-image>
		<b class="mask"></b>
		<div class="main">
			<!-- <div class="cont">
				<img src="../../assets/imgs/banner.png" alt="">
				<p style="font-size: 150px;">启航计划</p>
				<p>肿瘤科普作品征集项目</p>
				<b>项目周期：2024年3月-2025年4月</b>
				<b>指导发起单位：中国初级卫生保健基金会</b>
				<b>执行单位：北京生命绿洲公益服务中心</b>

				<b>指导发起单位：中国初级卫生保健基金会</b>
				<b>执行单位：北京生命绿洲公益服务中心</b>
				<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" alt=""></b>
			</div> -->
			<!-- <img class="frame" src="../../assets/imgs/Frame.png" alt=""> -->
			<!-- <el-button type="primary" @click="$router.push({path:'Intr'})">了解更多</el-button> -->
		</div>

		<div class="inputSearth" @click="$router.push({path:'/contribute',query:{type:'searth'}})">
			优秀征文查询
			<i slot="prepend" class="el-icon-search"></i>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pat1',
		props: {
			bannerPath: String
		},
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>
<style lang="scss" scoped>
	.pat1 {
		width: 1200px;
		height: 560px;
		margin: 20px auto 80px;
		position: relative;
		border-radius: 30px;

		.inputSearth {
			position: absolute;
			left: 50%;
			bottom: 0;
			width: 1000px;
			height: 100px;
			background-color: #fff;
			transform: translate(-50%, 60%);
			display: flex;
			align-items: center;
			justify-content: center;
			color: #999;
			border-radius: 20px;
			font-size: 20px;
			z-index: 99;
			box-shadow: 0px 43px 66px rgba(0, 0, 0, 0.07);
			padding: 0 20px 0 50px;
			display: flex;
			justify-content: space-between;

			.el-icon-search {
				height: 60px;
				width: 60px;
				background-color: #28B0A6;
				border-radius: 10px;
				font-size: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
			}
		}

		.frame {
			position: absolute;
			height: 140%;
			right: -40px;
			top: 50%;
			transform: translateY(-50%);
		}

		.webHander {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			z-index: 1;
		}

		.background {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 0;
			border-radius: 30px;
			overflow: hidden;
		}

		.mask {
			display: none;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 0;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
		}

		.main {
			position: absolute;
			width: 1200px;
			height: 560px;
			top: 0;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;

			.cont {
				width: 600px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-right: 100px;

				>img {
					height: 180px;
					margin-bottom: 20px;
				}

				p {
					font-size: 60px;
					font-weight: 600;
					line-height: 1.5;
				}

				b {
					font-size: 16px;
					font-weight: normal;
					margin-top: 12px;
					color: #fff;
					text-shadow: 0 0 5px #333;
					opacity: .8;
					line-height: 1.5;

					img {
						height: 36px;
					}
				}
			}

			.el-button {
				font-size: 16px;
				// background-color: #000;
				// color: #fff;
				width: 180px;
				height: 66px;
				line-height: 65px;
				border-radius: 33px;
				padding: 0;
				margin-top: 70px;
			}
		}
	}
</style>